<template>
  <v-switch :class="classes" v-model="editMode" light :label="name"></v-switch>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    classes: {
      type: String,
    },
  },

  computed: {
    editMode: {
      get() {
        return this.$store.getters.editMode;
      },
      set(value) {
        this.$store.commit("setEditMode", value);
      },
    },
  },
};
</script>

<style>
</style>